import React from 'react';
import mailtoLink from 'mailto-link';

// import { SubscribeModal } from 'gatsby-theme-newsletter/src/components/SubscribeModal';
import CallToAction from 'gatsby-theme-newsletter/src/components/CallToAction';

import Layout, { Block, Hero, ImageBlock, NarrowBlock } from '../../../components/Layout';
import SEO from '../../../components/Seo';
import { Faq, PricePlan } from '../../../components';

import styles from './index.module.scss';
import { ImageAlignment } from '../../../components/Layout/components/ImageBlock/ImageBlock';
import MockupPhone from '../../../components/MockupPhone';

const Video = (
  <MockupPhone>
    <video className={styles.screen} autoPlay loop>
      <source src="../../QRpid_Lost-And-Found_Prototype.mp4" type="video/mp4" />
    </video>
  </MockupPhone>
);

// const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
//   <CallToAction target="/de/qrpid-waiter-lockdown-angebot/demo">{children}</CallToAction>
// );

const subject = 'QRpid Lost & Found: Termin für Demo vereinbaren';
const body =
  'Guten Tag!\n\n\nIch möchte gerne einen Termin für eine Demo vereinbaren. Meine Kontaktdaten lauten wie folgt:\n\n\nFirma:\n\nAnsprechpartner:\n\nTelefonnummer:\n\n\n\nMit freundlichen Grüßen';

const mailto = mailtoLink({ subject, body, to: 'support@qrpid.io' });

const DemoCTA = ({ children = 'Jetzt Demo anfordern!' }) => (
  <CallToAction target={mailto}>{children}</CallToAction>
);

const pricePlans: any = {
  basic: {
    name: 'Basic',
    price: '19',
    strikethroughPrice: '29',
    currency: '€',
    condition: 'pro Monat / zzgl. MwSt.',
    promotion: 'Lockdown Angebot bis 30.11.2020',
    cta: <DemoCTA>Demo anfordern</DemoCTA>,
    benefits: [
      'Bis zu 20 Tische verwalten',
      '1 Admin User',
      '100.000 Tischrufe',
      'Telegram Integration',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  pro: {
    name: 'Pro',
    price: '39',
    currency: '€',
    strikethroughPrice: '59',
    condition: 'pro Monat / zzgl. MwSt.',
    promotion: 'Lockdown Angebot bis 30.11.2020',
    cta: <CallToAction>Demo anfordern</CallToAction>,
    benefits: [
      'Bis zu 100 Tische verwalten',
      'Bis zu 5 Admin User',
      '1.000.000 Tischrufe',
      'Telegram Integration',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
  custom: {
    name: 'Custom',
    price: '?',
    condition: '-',
    cta: <CallToAction>Demo buchen</CallToAction>,
    benefits: [
      'Bis zu ? Tische verwalten',
      '? Admin User',
      '? Tischrufe',
      'Telegram Integration',
      'Support bei Ersteinrichtung',
      'E-Mail Support',
    ],
  },
};

const faqs = [
  {
    question: 'Wie erhalte ich die QR Codes für meine Tische?',
    answer:
      'Die QR Codes werden für jeden Tisch individuell innerhalb des online QRpid Portals erstellt. Wir empfehlen, mit einem eigenen Ausdruck zu beginnen. Das hält die Kosten niedrig und Sie können sofort starten.',
  },
  {
    question: 'Was ist, wenn mein Gast kein Smartphone hat?',
    answer:
      'Ihr Gast hat natürlich auch weiterhin die Möglichkeit einen Mitarbeiter an seinen Tisch zu rufen. Alternativ kann natürlich auch ein netter Tischnachbar mit Smartphone den Tischruf stellvertretend durchführen.',
  },
  {
    question: 'Benötigen alle Mitarbeiter ein Smartphone?',
    answer:
      'Nein. Grundsätzlich reicht auch ein zentral zugängliches Smartphone, Tablet oder Notebook aus. Natürlich werden Mitarbeiter mit eigenem Smartphone immer schneller reagieren können.',
  },
  {
    question: 'Können meine Mitarbeiter ihr eigenes Smartphone nutzen?',
    answer:
      'Natürlich. Das ist sogar die Grundidee. Zu Beginn einer Schicht können Sie sich in der Gruppe ein- und danach auch wieder austragen.',
  },
  {
    question: 'Was ist der Unterschied zwischen Telegram und WhatsApp?',
    answer:
      'Telegram ist sicherer in der Kommunikation und bietet viele interaktive Features, welche die Kommunikation deutlich vereinfachen.',
  },
  {
    question: 'Wenn ich meinen Zugang kündige, erhalte ich dann eine Gutschrift?',
    answer:
      'Wenn Sie innerhalb der 30 Tage Geld-zurück-Garantie kündigen, erhalten Sie den gesamten Betrag zurück. Sollten Sie zu einem späterem Zeitpunkt kündigen erhalten Sie für den angefangenen Monat keine Gutschrift. Allerdings bleibt der Zugang auch bis zum Ende des monatlichen Abrechnungszeitraumes aktiv. Danach wird der Zugang deaktiviert und Sie erhalten keine weitere Rechnung.',
  },
  {
    question: 'Wie geht es weiter, nachdem ich einen Demo-Termin vereinbart habe?',
    answer:
      'Sie installieren den Telegram Messenger auf Ihrem Smartphone und wir senden Ihnen eine PDF Vorlage mit 10 Tischkarten zum Ausdrucken zu. Dann laden wir Sie in eine unserer Testgruppe ein. Dort können Sie direkt mit dem Scannen der Tischkarten beginnen und die Anfragen in Telegram beantworten. Gerne laden wir auch noch Mitarbeiter oder Freunde zum Testen mit ein.',
  },
];

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const IndexPage = () => (
  <Layout>
    <SEO
      title="QRpid Lost &amp; Found | QRpid Next Level QR-Codes"
      description="Web based software to manage anything with our next level QR-Codes for service and support, distribution, tracking and feedback solutions."
      keywords={[`qrcodes`, `assets`, `devices`, `manage`]}
    />
    <Hero video={Video}>
      <h1>Ein QR Code für das, was Ihnen lieb und teuer ist.</h1>
      <p>
        Zwischen Erstbestellung, Nachbestellung und Zahlvorgang warten Ihre Gäste unnötig lange.
        QRpid Waiter hilft, diese Zeiten erheblich zu reduzieren und so den Umsatz zu steigern!
      </p>
      <DemoCTA />
    </Hero>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Wie genau funktioniert das?</h1>
        <p>
          Mit QRpid Waiter platzieren Sie auf jedem Tisch einen individuellen QR Code. Ihr Gast
          scannt diesen über sein Smartphone und kann direkt eine Bedienung an den Tisch rufen.
          Selbst wenn so nur jeder vierte Gast ein Getränk mehr bestellt, kommt dadurch im Monat
          einiges zusammen.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/waiter/features/QRpid_Waiter_Features_03.png"
        alignment={ImageAlignment.RIGHT}
        alt="QR Code examples"
        className="text-left"
      >
        <h3>Individuelle QR Codes pro Tisch.</h3>
        <ul>
          <li>Der Gast ruft die Bedienung per QR Code auf Aufstellern, Karten oder Auflegern.</li>
          <li>
            Alle Mitarbeiter sehen auf Ihren Smartphones den Tischruf und können entscheiden, wer
            diesen annimmt.
          </li>
          <li>
            Während der Besuchsdauer eines Gastes können mehr Bestellungen aufgegeben werden, da
            zwischen Bestellwunsch und Bestellaufnahme weniger Zeit vergeht.
          </li>
          <li>
            Sobald der Gast zahlen möchte, kann der zuständige Mitarbeiter abrechnen und der Tisch
            ist wieder frei.
          </li>
          <li>
            Als Verantworlicher können sind Sie jederzeit im Bilde, auch ohne vor Ort zu sein.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps}>
      <NarrowBlock className="text-center">
        <h1>Was muss mein Gast tun?</h1>
        <p>
          Ihr Gast scannt den QR Code mit seinem Smartphone und kann die Bedienung rufen. Ganz ohne
          App Installation.
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/waiter/features/QRpid_Waiter_Features_02.png"
        alt="Web App examples"
        className="text-left"
      >
        <h3>Gäste bestellen über das eigene Smartphone.</h3>
        <ul>
          <li>Der Gast scannt den QR Code mit seinem Smartphone.</li>
          <li>Es ist Keine Installation einer App notwendig.</li>
          <li>Der Gast entscheidet, wann er bedient werden möchte.</li>
          <li>Nachbestellungen und Zahlvorgang werden ebenfalls durch den Gast gesteuert.</li>
          <li>
            Der Gast ist zufriedener, da es nun leichter ist, die Bedienung auf sich aufmerksam zu
            machen.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Wie werden meine Mitarbeiter eingebunden?</h1>
        <p>
          Warum sollten wir das Rad neu erfinden, wenn es mit Telegram bereits einen fantastischen
          Messenger gibt, den weltweit über 400 Millionen Menschen nutzen?
          <br />
          Genau, das dachten wir uns auch!
        </p>
      </NarrowBlock>
      <ImageBlock
        src="https://s3.eu-central-1.amazonaws.com/static.qrpid.io/waiter/features/QRpid_Waiter_Features_01.png"
        alignment={ImageAlignment.RIGHT}
        alt="Telegram Code examples"
        className="text-left"
      >
        <h3>Interne Kommunikation über die Telegram App.</h3>
        <ul>
          <li>Sie laden alle Mitarbeiter und den QRpid Waiter Bot in eine Gruppe ein.</li>
          <li>Der QRpid Waiter Bot leitet Anfragen von Gästen direkt in die Gruppe weiter.</li>
          <li>
            Ihre Mitarbeiter erhalten den Tischruf einfach per Push-Benachrichtigung aufs
            Smartphone.
          </li>
          <li>Einen angenommenen Tischruf können alle anderen Mitarbeiter sehen.</li>
          <li>
            Sie haben die Übersicht verloren? Lassen Sie sich einfach den aktuellen Status aller
            Tische anzeigen!
          </li>
          <li>
            Übrigens: Telegram funktioniert auch auf Tablets, Smartwatches, Macs sowie
            Windows-PC&apos;s und bietet viele weitere Funktionen, wie z.B. Umfragen oder
            zeitgesteuerte Nachrichten.
          </li>
        </ul>
        <DemoCTA />
      </ImageBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>30 Tage Geld-zurück-Garantie.</h1>
        <p>
          Wenn Sie innerhalb des ersten Monats kündigen, erhalten Sie den gesamten Betrag zurück.
          Ohne Angabe von Gründen!
        </p>
      </NarrowBlock>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell small-12">
            <div className={styles.pricePlans}>
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.basic} />
              <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.pro} />
              {/* <PricePlan className={styles.pricePlans__plan} pricePlan={pricePlans.custom} /> */}
            </div>
          </div>
        </div>
      </div>
      <NarrowBlock className={`text-center`}>
        <h3>
          Einfache Einrichtung.
          <br />
          Vorhandene Ausstattung nutzen.
          <br />
          Monatlich kündbar.
        </h3>
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1 className="text-center">Häufig gestellte Fragen.</h1>
        <Faq faqs={faqs} />
      </NarrowBlock>
    </Block>
    <Block className={styles.steps__light}>
      <NarrowBlock className="text-center">
        <h1>Neugierig geworden?</h1>
        <p>
          Dann machen Sie ganz einfach über unser Formular einen Termin mit uns aus. Wir melden uns
          dann schnellstmöglich und besprechen die weiteren Schritte.
        </p>
        <div>
          <DemoCTA>Termin für Demo vereinbaren</DemoCTA>
        </div>
      </NarrowBlock>
    </Block>
  </Layout>
);

export default IndexPage;
